@import "font-face-mixin.scss";
@include font-face("Roboto", "Regular", $packageKey: "Pende.MainWebsite");
@include font-face("Roboto", "Italic", $packageKey: "Pende.MainWebsite");
@include font-face("Roboto", "Medium", $packageKey: "Pende.MainWebsite");
@include font-face("Roboto", "MediumItalic", $packageKey: "Pende.MainWebsite");
@include font-face("Roboto", "Bold", $packageKey: "Pende.MainWebsite");
@include font-face("Roboto", "BoldItalic", $packageKey: "Pende.MainWebsite");


@import 'variables.scss';
@mixin headline {
    position: absolute;
    bottom: 20px;
    @media (min-width: $sm-view) {
        bottom: 40px
    }
    @media (min-width: $md-view) {
        bottom: 50px
    }
    h1 {
        font-weight: 100;
        color: white;
        text-shadow: 2px 2px 5px rgba(0, 0, 0, .6);
        @media (min-width: $sm-view) {
            font-size: 50px
        }
        @media (min-width: $md-view) {
            font-size: 60px
        }
    }
    h2 {
        font-weight: 100;
        background: white;
        color: $orange;
        padding: 5px 8px 5px 8px;
        display: inline-block;
        @media (min-width: $sm-view) {
            font-size: 25px
        }
    }
}

html {
    position: relative;
    min-height: 100%;
    overflow-x: hidden;
    body {
        margin: 0 0 40px;
        padding: 0;
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        text-rendering: optimizeLegibility !important;
        -webkit-font-smoothing: antialiased !important;
        .vr-left {
            padding-left: 8px;
            margin-left: 6px;
            border-left: 1px solid $darkgrey;
        }
        a {
            color: inherit;
            cursor: pointer;
            text-decoration: none;
            &:hover,
            &:active,
            &:focus {
                color: $orange;
            }
        }
        #swipebox-overlay {
            background: $slightlygrey;
        }
        @import 'swiper.min.scss';
        // import here your own scss files
        @import 'NodeTypes/contact-block.scss';
        @import 'NodeTypes/info-slider.scss';
        @import 'NodeTypes/image-link-slider.scss';
        @import 'NodeTypes/modal-gallery.scss';
        @import 'NodeTypes/team-card.scss';
        @import 'NodeTypes/form.scss';
        @import 'NodeTypes/modal.scss';
        @import 'NodeTypes/sitemap.scss';
        @import 'NodeTypes/rundgang.scss';
        @import 'Objects/header-section.scss';
        @import 'Objects/header.scss';
        main {
            h1,
            h2,
            h3 {
                font-size: 20px;
                @media (min-width: $sm-view) {
                    font-size: 24px;
                }
            }
            .container,
            .container-fluid,
            .swiper-container {
                margin-bottom: 20px;
            }
            &>.neos-contentcollection {
                &>.neos-nodetypes-headline {
                    color: $orange;
                    margin: 20px;
                    font-size: 35px;
                }
                &>.neos-nodetypes-text {
                    line-height: 1.8em;
                    font-size: 17px;
                }
            }
            .col-sm-6.neos-contentcollection {
                &>.neos-nodetypes-headline {
                    font-size: 21px;
                    color: $orange;
                }
                &>.neos-nodetypes-text {
                    font-size: 14px;
                    color: $darkgrey;
                }
            }
            svg {
                max-height: 100px;
                & * {
                    fill: $orange !important;
                }
                & .colorless {
                    fill: none !important;
                }
            }
            .neos-nodetypes-text {
                ul {
                    list-style: none;
                    li {
                        position: relative;
                        padding-left: 30px;
                        &:before {
                            content: '\2022';
                            color: $orange;
                            display: inline-block;
                            width: 1em;
                            margin-left: -1em;
                            font-size: 15px;
                        }
                    }
                }
            }
            .icon-tag {
                text-align: center;
                margin: 25px 0px 15px;
            }
            .icon-tag svg {
                max-height: 100px;
            }
            .neos-nodetypes-image,
            .neos-nodetypes-textwithimage {
                &>figure {
                    overflow: hidden;
                    width: 100%;
                    height: auto;
                }
                a.swipebox>img {
                    overflow: hidden;
                    transform: scale(1);
                    transition: transform 0.2s ease-in-out;
                    &:hover {
                        transform: scale(1.2);
                    }
                }
            }
        }
        footer {
            @import 'Objects/footer.scss';
        }
        // Color "hack" for blue text highlighting
        sup,
        .blued {
            font-size: 100%;
            top: 0;
            line-height: inherit;
            a:hover {
                color: $blue !important;
            }
        }
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        .h1,
        .h2,
        .h3,
        .h4,
        .h5,
        .h6,
        .title {
            & sup {
                color: $blue;
            }
        }
        #swipebox-close {
          background-color: $orange;
          right: 1%;
          top: 1%;
        }
    }
}

@import 'hotfix.scss';
