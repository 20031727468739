.team-card {
  min-height: 150px;
  background-position: center center;
  background-size: contain;

  img {
    max-width: 100%;
    height: auto;
  }

  .overlay {
    margin-top: 35%;
    margin-bottom: -35%;
    position: relative;
    z-index: 1;
    margin-left: 40px;
    @media (max-width: $md-view) {margin-left: 25px;}

    .text {
      font-size: 14px;
      @media (max-width: $md-view) {font-size: 13px;}
      color: $mediumgrey;

      strong, b {
        text-transform: uppercase;
      }
    }
  }
}
