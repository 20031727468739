.header-section {
  height: 100vh;
  @media (min-width: $sm-view) {height: 700px;}
  overflow: hidden;
  margin-bottom: 50px;
  padding: 0;

  .gradient-overlay {
    height: 100%;
    background: -moz-linear-gradient(top, rgba(79,79,79,0.4) 0%, rgba(185,185,185,0) 60%, rgba(255,255,255,0) 100%);
    background: -webkit-linear-gradient(top, rgba(79,79,79,0.4) 0%,rgba(185,185,185,0) 60%,rgba(255,255,255,0) 100%);
    background: linear-gradient(to bottom, rgba(79,79,79,0.4) 0%,rgba(185,185,185,0) 60%,rgba(255,255,255,0) 100%);
  }

  .header-slider {
    height: 100%;
    @import '../NodeTypes/slide.scss';

    .static-header-image {
      position: relative;
      height: 100vh;
      @media (min-width: $sm-view) {height: 100%;}
      width: 100%;
      background-position: center top;
      background-repeat: no-repeat;
      background-size: cover;

      .headline {
        @include headline;
      }
    }
  }
 // OLD STUFF
  .title-overlay {
    color: white;
    max-width: 25%;
    @media (max-width: 1250px) {max-width: 35%;}
    @media (max-width: 930px) {max-width: 45%;}
    position: absolute;
    top: 180px;

    .title {
      font-size: 65px;
      // @media (max-width: 650px) {font-size: 40px;}
      font-weight: 600;
    }

    .subtitle {
      background: white;
      color: $orange;
      font-size: 25px;
      padding: 5px 8px 5px 8px;
    }
  }

  .info-wrapper {
    position: relative;
    z-index: 1;

    .info-wrapper-inner {
      position: relative;
      text-align: center;
      color: white;
      height: 100%;

      .info-slideup {
        position: absolute;
        transition: bottom 0.4s ease-in-out;

        &.in {
          bottom: 0px !important;
        }

        &:nth-of-type(1) {
          background: rgba(0,0,0,0.7);
          left: 0;
        }
        &:nth-of-type(2) {
          background: rgba(0,0,0,0.6);
          left: calc(100% / 2);
        }
        /*
        &:nth-of-type(3) {
          background: rgba(0,0,0,0.5);
          left: calc(100% / 3 * 2);
        }
        */
        .content-wrapper {
          padding: 10px;
          max-width: 100%;
          word-wrap: break-word;
          word-break: break-all;

          .pseudo-title {
            text-transform: uppercase;
            font-weight: 600;
            line-height: 15px;
            max-height: 30px;
            font-size: 14px;
            margin-top: 5px;
            margin-bottom: 11px;
            display: block;
          }

          p {
            margin-bottom: 0;
            // height: 120px;
            @media (min-width: $sm-view) and (max-width: $md-view) {
              // height: 140px;
            }
            font-size: 14px;

            u {
              text-decoration: none;
              font-weight: 700;
            }
          }
        }
      }
    }
  }
}


.info-slideup.not-animated {
  text-align: center;
  color: white;

  &:nth-of-type(1) {background: rgba(0,0,0,0.7);}
  &:nth-of-type(2) {background: rgba(0,0,0,0.6);}
  &:nth-of-type(3) {background: rgba(0,0,0,0.5);}

  .content-wrapper {
    padding: 10px;
    max-width: 100%;
    word-wrap: break-word;
    word-break: break-all;

    h4 {
      text-transform: uppercase;
      font-weight: 600;
      font-size: 15px;
    }
  }
}
