.contact-block {
  padding: 50px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 30px;
  margin-bottom: 30px;

  .form-card {
    background: $whiteOpaque;
    padding: 15px 30px 5px 30px;

    .title-wrapper {
      margin: 40px 40px 40px 15px;

      .title {
        color: $orange;
        font-size: 26px;
        text-transform: uppercase;
        font-weight: 600;
      }
    }
  }


  .info-cards {
    background-color: $slightlygrey;
    padding: 0;
    margin-top: 10px;
    @media (min-width: $sm-view) {margin-top: 0;}

    .info-wrapper {
      padding: 0;
      @media(min-width: $sm-view) {
        //margin-left: 30px;
      }
      margin-bottom: 10px;
      height: 32.4%;
      //display: table;

      .info-card-left {

        .infoIcon {
          display: inline;
          float: left;
          margin-right: 15px;
          color: $orange;
          font-size: 20px;
        }

        background-color: none;
        padding: 10px;
        @media (min-width: $sm-view) {
          padding: 30px;
        }
        height: 100%;
        display: table-cell;
        vertical-align: middle;
        text-align: left;
        word-break: break-all;
        word-wrap: break-word;

        .title {
          margin-top: 0;
          color: $orange;
          text-transform: uppercase;
          font-size: 18px;
          display: inline;
          float: left;

          text-align: center;
          @media (min-width: $sm-view) {
            text-align: left;
          }
        }

        .text {
          color: $orange;
          font-size: 16px;
          display: inline;
          float: left;
          margin-bottom: 20px;

          a:active, a:focus, a:hover {
            color: $orange;
          }

        }
      }

      .info-card-right {
        background-color: none;
        padding: 10px;
        @media (min-width: $sm-view) {
          padding: 30px;
        }
        height: 100%;
        display: table-cell;
        vertical-align: middle;
        text-align: left;
        word-break: break-all;
        word-wrap: break-word;

        .infoIcon {
          display: inline;
          float: left;
          margin-right: 15px;
          color: $blue;
          font-size: 20px;
        }


        .title {
          margin-top: 0;
          color: $blue;
          text-transform: uppercase;
          font-size: 18px;
          display: inline;
          float: left;

          text-align: center;
          @media (min-width: $sm-view) {
            text-align: left;
          }
        }

        .text {
          color: $blue;
          font-size: 16px;
          display: inline;
          float: left;
          margin-bottom: 20px;

          a:active, a:focus, a:hover {
            color: $blue;
          }


        }
      }



    }
  }
  .info-logo {
    padding-left: 33%;
    padding-right: 33%;
    @media (max-width: $sm-view) {
      padding-left: 0;
      padding-right: 0;
    }
  }

}
