@mixin placeholderFix {
  box-sizing: content-box;
  max-width: 95%;
  padding: 6px 12px !important;
}

// FF only
_:-moz-any-link, :root input[type="text"] {
  @include placeholderFix;
}

// IE only
_:-ms-input-placeholder, :root input[type="text"] {
  @include placeholderFix;
}

// EDGE only
_:-ms-lang(x), _:-webkit-full-screen, .info-block {
  z-index: 5;
}
