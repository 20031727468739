// color settings
$whiteOpaque: #ffffff;
$blue: #51B7DE;
$orange: #f07d00;
$slightlygrey: #e6e6e6;
$grey: #909090;
$mediumgrey: #777777;
$darkgrey: #4f4f4f;
$whiteTransparent: rgba(255,255,255,0.7);
$whiteLittleTransparent: rgba(255,255,255,0.9);

$listOdd: #cccccc;
$listEven: #e0e0e0;
