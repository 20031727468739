.rundgang {
    position: relative;
    padding: 26px;
    .overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}