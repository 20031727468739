.info-slider {
  height: 500px;

  .swiper-wrapper {
      .swiper-slide {
        padding: 25px;

        .info-block {
          background-position: center !important;
          background-repeat: no-repeat !important;
          background-size: cover !important;
          height: 100%;
          overflow: hidden;
          position: relative;

          &.not-animated {
            .content-wrapper {
              top: 20px;
              bottom: auto;
            }
          }

          &.animated.shown {
            .content-wrapper {bottom: 0px !important;}
          }

          .content-wrapper {
            transition: bottom 0.3s ease-in-out;
            position: absolute;
            bottom: 0px;

            .title-wrapper {
              color: $orange;
              margin-left: 20px;
              margin-right: 20px;
              margin-bottom: 30px;

              & > * {
                display: inline-block;
              }

              &>h3 {
                background: rgba(255,255,255,0.7);
                padding: 5px;
                text-transform: uppercase;
                font-size: 24px;
                margin-bottom: 0;
              }
              &>h4 {
                background: rgba(255,255,255,0.7);
                padding: 5px;
                font-size: 18px;
                margin-top: 0px;
              }
            }

            .text-wrapper {
              background: rgba(255,255,255,0.7);
              color: $grey;
              margin-left: 20px;
              margin-right: 20px;
              margin-bottom: 30px;
              padding: 5px;

              p {
                margin: 0;
                font-size: 16px;
              }
            }
          }
        }
      }
  }

  .swiper-button-prev, .swiper-button-next {
    color: $orange;
    background: white;
    margin-left: 15px;
    margin-right: 15px;
    width: 45px;
    height: 45px;
    font-size: 50px;

    &:hover {
      color: white;
      background-color: $orange;
    }

    .fa {
      display: block;
      margin-top: -4px;
      margin-left: 7px;
    }
  }
}
