.modal-wrapper {
  position: absolute;
  z-index: 10001;

  .modal-button-box {
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }

  .modal-dialog {
    .modal-content {
      border-radius: 0px;

      .modal-header {
        color: $orange;

        .title {
          margin: 0;
        }

        .close:hover {
          color: $orange;
        }
      }
      .modal-body {}
    }
  }
}
