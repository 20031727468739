.neos-nodetypes-menu {
  color: $orange;

  ul.nav-stacked {
    list-style: none;

    &>li {
      font-weight: bold;

      &:first-of-type {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }

      &:last-of-type {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }

      &:nth-child(odd) {
        background: $listOdd;
      }
      &:nth-child(even) {
        background: $listEven;
      }
    }

    ul {
      list-style: none;

      &>li {
        padding: 3px 10px 3px 10px;
        font-weight: normal;
        color: $darkgrey;

        &:nth-child(odd) {
          background: lighten($listOdd,10);
        }
        &:nth-child(even) {
          background: lighten($listEven,5);
        }

        &::before {
          content: '\2022';
          color: $orange;
          display: inline-block;
          width: 1em;
          margin-left: -2em;
          margin-right: 1em;
          font-size: 15px;
        }
      }
    }
  }

  .nav>li>a:focus, .nav>li>a:hover {
    background: $orange;
    color: white;
  }
}
