.header-slider-item {
  position: relative;
  height: 100vh;
  @media (min-width: $sm-view) {height: auto;}
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .headline {
    @include headline;
  }
}
