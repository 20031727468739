header {
  .navbar {
    background: $whiteTransparent;
    @media (max-width: $sm-view) {
      background: $whiteLittleTransparent;
    }
    position: fixed;
    top: 0px;
    @media (min-width: $sm-view) {
      top: 15px;
    }
    width: 100%;
    border: none;
    transition: all 0.3s ease-in-out;

    &.noopac {
      background: $whiteLittleTransparent;
    }

    .navbar-header {
      width: 100%;
      height: auto;
      @media (min-width: $sm-view) {
        height: 100px;
      }
      padding: 10px;

      &>div {
        height: auto;
      }

      .left-col {
        @media (min-width: $sm-view) {
          height: 100%;
        }

        .upper-row {
          color: $darkgrey;
          margin-top: 10px;
          font-size: 13px;
          text-transform: uppercase;
          border-bottom: 1px solid $darkgrey;

          ul {
            display: inline-block;
            li {
              a {
                i.fa {
                  font-size: 28px;
                }
              }
            }
          }

          a {
            text-decoration: none;
            color: $darkgrey;
          }

          .impress-link .modal-wrapper {
            position: relative;
            display: inline-block;
          }
        }

        .lower-row {
          font-size: 15px;
          height: 50%;

          ul.nav {
            margin-left: -15px;
            width: 100%;
            @media (max-width: $sm-view) {
              margin-bottom: 25px;
            }

            .menu-item {
              padding: 15px;
              text-align: left;
              @media(max-width: $sm-view) {
                padding: 5px;

                &>a {
                  border: none;
                }
              }

              @media (max-width: $md-view) and (min-width: $sm-view) {
                font-size: 13px;
                padding: 12px;

                .vr-left {
                  padding-left: 12px;
                  margin-left: -12px;
                }
              }

              &.active, &.active>a, &.dropdown.open, &.dropdown.open>a {
                color: $orange;
              }

              a {
                color: $darkgrey;
                font-weight: 500;
                text-transform: uppercase;
                padding: 0;
                background: none;

                &.vr-left {
                  padding-left: 15px;
                  margin-left: -15px;
                }

                &:hover {
                  color: $orange;
                }

                &.left-align {
                  float: left;
                }

                &.dropdown {
                  display: inline-block;
                }
              }

              .dropdown-menu {
                background:  $whiteTransparent;
                @media (max-width: $sm-view) {
                  background: transparent;
                }
                @media (width: $sm-view) {
                  background: $whiteLittleTransparent;
                }
                border-radius: 0;
                border: none;
                box-shadow: none;
                margin-top: 0px;
                padding: 0;
                padding-bottom: 15px;
                @media(max-width: $sm-view) {
                  padding-bottom: 0;
                  padding-top: 15px;
                }
                top: 101%;
                @media (max-width: $md-view) and (min-width: $sm-view) {
                  top: 115%;
                }
                width: 100vw;

                &:before {
                  background: $whiteTransparent;
                  content: '';
                  height: 100%;
                  left: -100%;
                  position: absolute;
                  top: 0;
                  width: 100%;

                  @media (max-width: $sm-view) {
                    background: $whiteLittleTransparent;
                  }
                }

                @media (min-width: $sm-view) {
                  &.noopac {
                    background: $whiteLittleTransparent;

                    &:before {
                      background-color: $whiteLittleTransparent;
                    }
                  }
                }

                .submenu-item {
                  text-decoration: none;
                  text-align: left;
                  padding: 2px;

                  @media(min-width: $sm-view) {
                    padding-left: 15px;
                  }

                  &.active a {
                    color: $orange;
                  }
                }
              }
            }
          }

          .nav-button {
            display: inline-block;
            width: 30px;
            line-height: 8px;

            .icon-bar {
              height: 2px;
              width: 30px;
              background: $darkgrey;
              display: inline-block;
            }
          }

          .menu-triggers {
            display: flex;
            align-items: center;
            position: relative;
            min-height: 60px;
            z-index: 5;

            @media(min-width: $sm-view) {
              display: none;
            }
          }
        }
      }

      .right-col {
        height: 100%;
        @media (min-width: $sm-view) {
          display: flex;
          align-items: center;
        }

        a {
          text-decoration: none;
          color: $darkgrey;
        }

        &.vr-left {
          margin-left: 0px;
        }

        .logo {
          max-width: 100%;
          transform: scale(1.0);
          @media (max-width: $md-view) and (min-width: $sm-view) {
            transform: scale(1.3);
            margin-left: 20px;
          }
        }

        @media(max-width: $sm-view) {
          border: none;

          .logo {
            float: right;
            position: absolute;
            right: -10px;
            height: auto;
            width: auto;
            max-width: 80%;
            max-height: 70px;
          }
        }
      }
    }
  }

  #navbar-collapse {
    max-height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .doctolib {
    a {
      position: fixed;

      @media (max-width: 768px) {
        top: 115px;
      }

      top: 118px;
      right: 0;
      display: block;
      text-align: center;
      opacity: 0.85;
      background-color: #0596de;
      color: #ffffff;
      font-size: 14px;
      overflow: hidden;

      @media (max-width: 768px) {
        width: 75px;
      }

      width: 100px;
      border-radius: 4px 0 0 4px;
      text-decoration: none;
      padding: 10px;
      line-height: 1.4;
      z-index: 99;

      span {
        @media (max-width: 768px) {
          font-size: 12px;
        }

        font-size: 14px;
      }

      img {
        height: 15px;
        margin-top: 10px;
        vertical-align: middle;
        width:auto
      }

    }
  }

}
