.upper-row {
  width: 100%;
  border-bottom: 1px solid $darkgrey;
  margin: 0;
  margin-bottom: 18px;
  padding-bottom: 18px;
  text-align: center;
  font-size: 15px;

  &>div {
    height: 40%;
    max-width: 100%;
    word-wrap: break-word;
    word-break: break-word;

    a {
      color: $darkgrey;
      text-decoration: none;
      cursor: pointer;

      &:hover {
        color: $orange;
      }
    }
  }

  .align-bottom {
    // height: 100%;
  }

  .middlecell {
    padding: 0;
  }

  .vr-left {
    margin: 0px;
    @media(max-width: $md-view) {
      border-left: 0;
      border-top: 1px solid grey;
      margin-top: 5px;
      padding-top: 5px;
    }
  }

  .title {
    text-align: center;
    height: 60%;

    img {
      width: 100%;
      max-width: 300px;
      height: auto;
      margin: 0 auto;
    }
  }
}

.lower-row {
  width: 100%;
  margin-top: 80px;
  text-align: center;
  color: $grey;

  .center-block {
    display: block;
    width: auto;

    .footer-button-collection {
      .link-wrapper {
        position: static;
        display: inline-block;
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }

        a {
          font-size: 1.3em;
          font-weight: 500;
          text-transform: uppercase;
        }
      }
    }
  }
}

.lowest-row {
  width: 100%;
  position: absolute;
  bottom: 0;
  display: inline-block;
  background-color: $orange;
  color: #ffffff;
  padding: 10px 10px 10px 10px;

  .container {
    .row {
      padding-left: 10px;
    }
  }

}
