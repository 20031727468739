.ajax-form {
  form {
    label {
      display: none;

      &[for="contact-form-surgery"] {
        display: block;
        color: $orange;
      }

      &[for="contact-form-dsgvo-checkbox01"] {
        display: inline;
        color: $grey;
      }

  }

    #contact-form-section-two {

      div:nth-child(1) {
        display: grid;
      }

      div:nth-child(2) {
        float:left;
      }

      span.required {
        display: none;
      }
      
    }

    input[type="text"], textarea {
      border-radius: 0;
      box-shadow: none;
      border: 1px solid #000000;
      padding: 20px;
    }

    textarea {
      resize: none;
    }

    .help-block {
      margin-top: 0px;
      margin-left: 30px;
      margin-bottom: 35px;
    }

    .form-group {
      margin-bottom: 30px;

      .form-checkbox {
        display: inline;
        margin-right: 5px;
      }

      .help-inline {
        color: darkred;
        margin-left: 20px;
      }


      .inputs-list {
        min-height: 25px;
        padding: 0;

        li {
          list-style: none;
          float: left;
          width: auto;
          margin-right: 10px;

          label {
            display: inline-block;
            color: $grey;
            font-weight: normal;
            font-size: 14px;
          }
        }
      }
    }

    nav.form-navigation {
      display: block;
      padding: 0 15px 0 15px;

      ul {
        padding-left: 0;
      }

      li.submit {
        margin-top: -15px;
        margin-bottom: 30px;
        list-style: none;
        @media (min-width: $sm-view) {
          width: 100%;
        }

        button {
          background: $whiteLittleTransparent;
          border: 1px solid $orange;
          color: $orange;
          min-height: 40px;
          width: 100%;
          border-radius: 0;
          font-weight: bold;
        }
      }
    }
  }
}
