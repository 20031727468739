.gallery {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50px;

  .gallery-item {
    padding: 10px;
    overflow: hidden;

    a {
      img {
        transform: scale(1);
        transition: transform .3s;

        &:hover {
          transform: scale(1.2);
          transition: transform .6s
        }
      }
    }

    svg * {
      fill: $orange;
    }

    svg .colorless {
      fill: none;
    }

    .inner {
      background: $whiteLittleTransparent;
      padding: 15px;
      text-align: center;
      height: 100%;
      transition: all 0.1s ease-in-out;

      .modal-button-box {
        cursor: pointer;

        &:hover {
          text-decoration: none;
        }

        img {
          width: 50%;
          height: auto;
          margin: 0 auto;
          display: block;
        }

        svg {
          width: auto;
          height: 75px;
          margin: 0 auto;
          display: block;
        }

        .title {
          color: $orange;
          text-transform: uppercase;
          margin: 5px;
          font-size: 21px;
        }

        .text {
          font-size: 15px;
          color: $darkgrey;
        }

        .more {
          color: $grey;
          margin: 0;
          margin-bottom: -5px;

          &:hover {
            color: $darkgrey;
          }
        }
      }

      &:hover {
        background: $orange;

        svg > *:not(.colorless) {
          fill: white !important;
        }

        .title, .text, .more {
          color: white;
        }
      }
    }
  }
}

.modal-dialog {
  .modal-content {
    border-radius: 0px;

    .modal-header {
      color: $orange;

      .title {
        margin: 0;
      }

      .close:hover {
        color: $orange;
      }
    }
    .modal-body {}
  }
}
